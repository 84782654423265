export default function createProduct (productAttributes) {
  return {
    ...productAttributes,

    get showStatus () {
      if (this.status === 'active') { return 'Ativo' }
      if (this.status === 'inactive') { return 'Inativo' }

      return 'STATUS_NAO_DEFINIDO'
    }
  }
}
