const formComponent = {
  props: {
    /*
    * autofocus: set field autofocus.
    */
    autofocus: {
      type: Boolean,
      default: false
    },
    /*
    * label: label text.
    */
    label: {
      type: String,
      required: true
    },
    /*
    * optional: define field as required.
    */
    optional: {
      type: Boolean,
      default: false
    },
    /*
    * value: field used as v-model.
    */
    value: {
      type: [String, Number],
      default: ''
    },
    /*
    * errorMessage: string wth a vuelidate error message or undefined.
    */
    errorMessage: {
      type: String,
      default: undefined
    }
  },

  methods: {
    hasError (errorMessage) {
      if (errorMessage === undefined) return ''

      return 'is-invalid'
    }
  }
}

export default formComponent
