export default function createOrderItem (attributes) {
  return {
    ...attributes,

    get formattedPrice () {
      return `R$ ${this.price.toString().replace('.', ',')}`
    },

    get formattedCost () {
      if (this.cost) return `R$ ${this.cost.toString().replace('.', ',')}`

      return ''
    }
  }
}
