import api from '@/lib/api'

export default {
  createClient (_, client) {
    return api.post('/clients', client)
  },

  updateClient (_, client) {
    return api.put(`/clients/${client.id}`, client)
  },

  getClient (_, id) {
    return api.get(`/clients/${id}`)
  },

  listClient (_, page) {
    return api.get(`/clients?page=${page}`)
  },

  searchClientByName (_, { page, name }) {
    return api.get(`/clients?page=${page}&name=${name}`)
  },

  listAllClients () {
    return api.get('/clients?all=true')
  }
}
