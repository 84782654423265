export default class SessionManager {
  // @param token [String] JWT token.
  static newSession (token) {
    localStorage.setItem('authToken', token)
  }

  static getAuthToken () {
    return localStorage.getItem('authToken')
  }

  static destroySession () {
    localStorage.removeItem('authToken')
  }
}
