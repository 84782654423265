<template>
  <modal
    title="Pedido"
    v-if="render"
    @closeModal="handleCloseModal"
  >
    <div>
      <label>Cliente: {{ order.clientName }}</label>
    </div>

    <div>
      <label class="mb-3">Estimativa de entrega: {{ order.deliveryForecast }}</label>
    </div>

    <order-item-list :orderItems="order.orderItems" />

    <div
      v-if="matrialPurchaseOrProgress"
      class="d-flex flex-column flex-md-row justify-content-md-between"
    >
      <router-link
        :to="{ name: 'show-order', params: { id: order.id }}"
        class="btn btn-outline-info mb-3"
      >
        Mais informações
      </router-link>

      <button
        class="btn btn-success mb-3"
        @click="handleAdvanceStatus"
      >
        Avançar
      </button>
    </div>

    <div v-if="readyFordelivery">
      <date-field
        label="Data de entrega"
        v-model="deliveredAt"
      />

      <div
        class="d-flex flex-column flex-md-row justify-content-md-between"
      >
        <router-link
          :to="{ name: 'show-order', params: { id: order.id }}"
          class="btn btn-outline-info mb-3"
        >
          Mais informações
        </router-link>

        <button
          class="btn btn-success mb-3"
          @click="handleAdvanceToDeliveredStatus"
        >
          Avançar
        </button>
      </div>
    </div>

    <div v-if="delivered">
      <date-field
        label="Pago em"
        v-model="paidAt"
      />

      <div
        class="d-flex flex-column flex-md-row justify-content-md-between"
      >
        <router-link
          :to="{ name: 'show-order', params: { id: order.id }}"
          class="btn btn-outline-info mb-3"
        >
          Mais informações
        </router-link>

        <button
          class="btn btn-success mb-3"
          @click="handleAdvanceToPaid"
        >
          Avançar
        </button>
      </div>
    </div>

  </modal>
</template>

<script>
import Modal from '@/components/Modal'
import OrderItemList from '../order_item/OrderItemList.vue'
import DateField from '../form/DateField.vue'

export default {
  components: {
    Modal,
    OrderItemList,
    DateField
  },

  props: {
    order: {
      type: Object,
      require: true
    },
    render: {
      type: Boolean,
      require: true
    }
  },

  data () {
    return {
      deliveredAt: new Date().toISOString().substr(0, 10),
      paidAt: new Date().toISOString().substr(0, 10)
    }
  },

  computed: {
    nextStatus () {
      if (this.order.status === 'material_purchase') { return 'progress' }
      if (this.order.status === 'progress') { return 'ready_for_delivery' }
      if (this.order.status === 'ready_for_delivery') { return 'delivered' }
      if (this.order.status === 'delivered') { return 'paid' }

      return undefined
    },

    matrialPurchaseOrProgress () {
      const status = this.order.status
      if (['material_purchase', 'progress'].includes(status)) { return true }

      return false
    },

    readyFordelivery () {
      if (this.order.status === 'ready_for_delivery') { return true }

      return false
    },

    delivered () {
      if (this.order.status === 'delivered') { return true }

      return false
    }
  },

  methods: {
    handleCloseModal () {
      this.$emit('closeOrderModal')
    },

    handleAdvanceStatus () {
      this.$store.dispatch('advanceOrderStatus', { id: this.order.id, newStatus: this.nextStatus })
        .then(() => {
          this.$emit('closeOrderModal')
          this.$emit('updateOrderStatus')
        })
    },

    handleAdvanceToDeliveredStatus () {
      this.$store.dispatch('advanceOrderStatus', {
        id: this.order.id,
        newStatus: this.nextStatus,
        deliveredAt: this.deliveredAt
      })
        .then(() => {
          this.$emit('closeOrderModal')
          this.$emit('updateOrderStatus')
        })
    },

    handleAdvanceToPaid () {
      this.$store.dispatch('advanceOrderStatus', {
        id: this.order.id,
        newStatus: this.nextStatus,
        paidAt: this.paidAt
      })
        .then(() => {
          this.$emit('closeOrderModal')
          this.$emit('updateOrderStatus')
        })
    }
  }
}
</script>
