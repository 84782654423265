<template>
  <div>
    <h2 class="mb-4">Clientes</h2>

    <form @submit.prevent="handleSearch" class="mb-4">
      <div class="mb-3">
        <input
          id="name-search"
          v-model="nameSearch"
          type="text"
          placeholder="Busca por parte do nome"
          class="form-control"
        >
      </div>
      <button class="btn btn-outline-info">
        Buscar
      </button>
    </form>

    <table class="table table-striped text-center">
      <thead>
        <th class="col-6">
          <h4>Nome</h4>
        </th>
        <th class="col-4">
          <h4>Telefone</h4>
        </th>
        <th class="col-2"></th>
      </thead>
      <tbody>
        <tr v-for="client of clients" :key="client.id" data-test="client">
          <td>{{ client.name }}</td>
          <td>{{ client.phone }}</td>
          <td>
            <router-link
              :to="{ name: 'show-client', params: { id: client.id } }"
              class="btn btn-sm btn-outline-info"
              data-test="show-client-link"
            >
              Exibir
            </router-link>

            <router-link
              :to="{ name: 'edit-client', params: { id: client.id } }"
              class="btn btn-sm btn-outline-warning ms-3"
              data-test="edit-client-link"
            >
              Editar
            </router-link>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
          <div class="btn-group" role="group" aria-label="First group">
            <button
              v-for="index in totalPages"
              :key="index"
              @click="changePage(index)"
              type="button"
              class=" btn btn-outline-secondary"
              :class="{'active' : index == currentPage}"
            >
              {{ index }}
            </button>
          </div>
        </div>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      clients: [],
      totalPages: 1,
      currentPage: 1,
      nameSearch: ''
    }
  },

  mounted () {
    this.loadClients()
  },

  methods: {
    changePage (newPage) {
      this.currentPage = newPage
    },

    handleSearch () {
      this.$store.dispatch('searchClientByName', {
        page: this.currentPage, name: this.nameSearch
      })
        .then(response => {
          this.clients = response.data.elements
          this.totalPages = response.data.total_pages
          this.currentPage = response.data.current_page
        })
    },

    loadClients () {
      this.$store.dispatch('listClient', this.currentPage)
        .then(response => {
          this.clients = response.data.elements
          this.totalPages = response.data.total_pages
          this.currentPage = response.data.current_page
        })
    }
  },

  watch: {
    currentPage: function () {
      this.loadClients()
    }
  }
}
</script>

<style>

</style>
