const formErrorHandler = {
  methods: {
    handleError (field) {
      if (field.$errors.length === 0) { return undefined }
      const message = field.$errors[0].$message
      if (message === 'Value must be numeric') return 'Valor deve ser numérico'
      if (message === 'Value is required') return 'Não pode ficar em branco'
    }
  }
}

export default formErrorHandler
