import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'
import NewSession from '../views/home/NewSession'
import ClientUpsert from '../views/client/ClientUpsert'
import ClientShow from '../views/client/ClientShow'
import ClientList from '../views/client/ClientList'
import ProductUpsert from '../views/product/ProductUpsert'
import ProductList from '../views/product/ProductList'
import ProductShow from '../views/product/ProductShow'
import OrderUpsert from '@/views/order/OrderUpsert'
import OrderShow from '@/views/order/OrderShow'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: Home, name: 'home' },
  { path: '/nova-sessao', component: NewSession, name: 'new-session' },
  { path: '/clientes/novo', component: ClientUpsert, name: 'new-client' },
  { path: '/clients/:id/editar', component: ClientUpsert, name: 'edit-client' },
  { path: '/clients/:id', component: ClientShow, name: 'show-client' },
  { path: '/clientes', component: ClientList, name: 'list-client' },
  { path: '/produtos', component: ProductList, name: 'list-product' },
  { path: '/produtos', component: ProductUpsert, name: 'new-product' },
  { path: '/produtos/:id', component: ProductShow, name: 'show-product' },
  { path: '/produtos/:id/editar', component: ProductUpsert, name: 'edit-product' },
  { path: '/pedidos', component: OrderUpsert, name: 'new-order' },
  { path: '/pedidos/:id', component: OrderShow, name: 'show-order' }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
