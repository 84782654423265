import createOrderItem from './order_item'

export default function createOrder (attributes) {
  return {
    ...attributes,

    get clientName () {
      if (this.client) { return this.client.name }

      return ''
    },

    get deliveryForecast () {
      if (this.delivery_forecast) {
        const dateValue = this.delivery_forecast.split('T')[0]
        const splittedDate = dateValue.split('-')
        const day = splittedDate[2]
        const month = splittedDate[1]
        const year = splittedDate[0]
        return `${day}/${month}/${year}`
      }

      return ''
    },

    get orderItems () {
      if (this.order_items === undefined) { return [] }

      return this.order_items.map(orderItem => {
        return createOrderItem(orderItem)
      })
    }
  }
}
