import axios from 'axios'

const api = axios.create({
  baseURL: 'http://localhost:3000/api', // TODO: extract to env variable
  timeout: 5000
})

api.interceptors.request.use(config => {
  const token = localStorage.getItem('authToken')
  if (token) { config.headers.Authorization = `Bearer ${token}` }

  return config
})

export default api
