import api from '@/lib/api'

export default {
  getProduct (_, id) {
    return api.get(`/products/${id}`)
  },

  createProduct (_, product) {
    return api.post('/products', product)
  },

  updateProduct (_, product) {
    return api.put(`/products/${product.id}`, product)
  },

  listProducts (_, page) {
    return api.get(`/products?page=${page}`)
  },

  listProductsByName (_, { page, name }) {
    return api.get(`products?page=${page}&name=${name}`)
  },

  listAllProducts () {
    return api.get('/products?all=true')
  }
}
