<template>
  <div class="order-upsert">
    <h1 data-test="title">{{ formTitle(this.orderId, 'Pedido') }}</h1>
    <form @submit.prevent="handleSubmit">
      <select-field
        :options="clients"
        label="Cliente"
        v-model="clientId"
        autofocus
        :errorMessage="handleError(this.$v)"
        data-test="client"
      />

      <date-field
        label="Previsão de entrega"
        v-model="deliveryForecast"
        data-test="delivery-forecast"
      />

      <hr>

      <div class="order-upsert__order-items-div">
        <div class="d-flex justify-content-between">
          <h2>Itens</h2>
          <button
            class="btn btn-outline-info"
            @click.prevent="renderOrderItemForm = true"
          >
            Novo item
          </button>
        </div>

        <add-order-item-modal
          :products="products"
          @click.prevent="handleNewOrderItemClick"
          @closeModal="renderOrderItemForm = false"
          @addOrderItem="handleAddOrderItem"
          v-if="renderOrderItemForm"
        />

        <div
          v-for="(orderItem, index) in orderItems"
          :key="orderItem.id"
          class="card card-body mb-2 d-flex justify-content-between flex-row"
        >
          <label>Produto: {{productName(orderItem.productId)}}</label>
          <button
            class="btn btn-sm btn-outline-danger"
            @click.prevent="removeOrderItem(index)"
          >
            x
          </button>
        </div>

      </div>

      <form-button>
        Salvar
      </form-button>

    </form>
  </div>
</template>

<script>
import SelectField from '@/components/form/SelectField'
import DateField from '../../components/form/DateField.vue'
import upsertMixin from '../mixins/upsert'
import formErrorHandler from '../mixins/form-error-handler'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import FormButton from '@/components/form/FormButton.vue'
import AddOrderItemModal from '../../components/order_item/AddOrderItemModal.vue'

export default {
  components: {
    SelectField,
    DateField,
    FormButton,
    AddOrderItemModal
  },

  mixins: [
    upsertMixin,
    formErrorHandler
  ],

  data () {
    return {
      clients: [],
      products: [],
      orderId: undefined,
      clientId: undefined,
      orderItems: [],
      deliveryForecast: undefined,
      renderOrderItemForm: false
    }
  },

  setup: () => ({ $v: useVuelidate() }),

  validations () {
    return {
      clientId: { required },
      deliveryForecast: { required }
    }
  },

  mounted () {
    this.$store.dispatch('listAllClients')
      .then(response => {
        this.clients = response.data.elements.map(client => {
          return { label: client.name, value: client.id }
        })
      })

    this.$store.dispatch('listAllProducts')
      .then(response => {
        this.products = response.data.elements
      })
  },

  computed: {
    orderPayload () {
      return {
        client_id: this.clientId,
        delivery_forecast: this.deliveryForecast,
        order_items: this.orderItemsPayload
      }
    },

    orderItemsPayload () {
      return this.orderItems.map(orderItem => {
        return {
          product_id: orderItem.productId,
          price: orderItem.price,
          description: orderItem.description
        }
      })
    }
  },

  methods: {
    handleAddOrderItem (orderItem) {
      this.orderItems.push(orderItem)
      this.renderOrderItemForm = false
    },

    productName (productId) {
      const selectedProduct = this.products.find(p => p.id === productId)
      return selectedProduct.name
    },

    formattedPrice (price) {
      return `R$ ${price.toString().replace('.', ',')}`
    },

    async handleSubmit () {
      if (!this.$v.$validate()) { return }

      this.$store.dispatch('createOrder', this.orderPayload)
        .then(response => {
          this.handleSuccess(response)
        })
        .catch(error => {
          this.formErrorHandler(error)
        })
    },

    handleSuccess (response) {
      this.$toast.success('Pedido cadastrado com sucesso.')
      const id = response.data.id
      this.$router.push({ name: 'show-order', params: { id: id } })
    },

    removeOrderItem (index) {
      this.orderItems.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
  .order-upsert {
    margin-bottom: 2em;

    &__order-items-div {
      margin-bottom: 1em;
    }

    &__order-items {
      list-style-type: none;
      display: flex;
      justify-content: space-between;
      margin-top: 1.2em;
      color: white;
    }
  }
</style>
