import Vuex from 'vuex'
import Vue from 'vue'
import clientStore from './client'
import productStore from './product'
import orderStore from './order'
import userStore from './user'

Vue.use(Vuex)

const state = {}

const actions = {
  ...clientStore.actions,
  ...productStore.actions,
  ...orderStore.actions,
  ...userStore.actions
}

export default new Vuex.Store({
  state,
  actions
})
