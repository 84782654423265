<template>
  <div class="mb-3">
    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>
