import api from '@/lib/api'

export default {
  createOrder (_, order) {
    return api.post('/orders', order)
  },

  orderGrouped () {
    return api.get('/orders?group=status')
  },

  getOrder (_, id) {
    return api.get(`/orders/${id}`)
  },

  advanceOrderStatus (_, { id, newStatus, deliveredAt, paidAt }) {
    const payload = { status: newStatus, delivered_at: deliveredAt, paid_at: paidAt }
    return api.patch(`/orders/${id}`, payload)
  }
}
