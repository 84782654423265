<template>
  <modal
     title="Novo item"
    @closeModal = "$emit('closeModal')"
  >
    <select-field
      :options="productOptions"
      label="Produto"
      v-model="productId"
      data-test="product"
      :errorMessage="handleError(this.$v)"
    />

    <number-field
      label="Preço"
      v-model="price"
      data-test="price"
      :errorMessage="handleError(this.$v)"
    />

    <text-field
      label="Descrição"
      v-model="description"
      data-test="description"
      optional
    />

    <button
      class="btn btn-outline-info"
      type="submit"
      @click.prevent="addOrderItem"
    >
      Adicionar
    </button>
  </modal>
</template>

<script>
import NumberField from '../form/NumberField.vue'
import SelectField from '../form/SelectField.vue'
import TextField from '../form/TextField.vue'
import Modal from '../Modal.vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import formErrorHandler from '../../views/mixins/form-error-handler'

export default {
  components: {
    Modal,
    SelectField,
    NumberField,
    TextField
  },

  mixins: [formErrorHandler],

  props: {
    products: {
      required: true,
      type: Array
    }
  },

  data () {
    return {
      productId: undefined,
      price: undefined,
      description: undefined
    }
  },

  computed: {
    productOptions () {
      return this.products.map(product => {
        return { label: product.name, value: product.id }
      })
    }
  },

  setup: () => ({ $v: useVuelidate() }),

  validations () {
    return {
      productId: { required },
      price: { required }
    }
  },

  methods: {
    addOrderItem () {
      if (!this.$v.$validate()) { return }

      const orderItem = {
        productId: this.productId,
        price: this.price,
        description: this.description
      }
      this.$emit('addOrderItem', orderItem)
    }
  },

  watch: {
    productId () {
      if (this.productId === undefined) return

      const selectedProduct = this.products.find(p => p.id === this.productId)
      this.price = selectedProduct.price
    }
  }
}
</script>
