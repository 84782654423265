<template>
  <div>
    <navbar :loggedIn="loggedIn" v-on:logout="handleLogout"/>
    <main class="container mt-3">
      <router-view v-on:login-success="handleLogin"></router-view>
    </main>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
/* eslint-disable camelcase */
import jwt_decode from 'jwt-decode'
/* eslint-enable camelcase */

export default {
  components: {
    Navbar
  },

  data () {
    return {
      loggedIn: this.isLoggedIn()
    }
  },

  methods: {
    handleLogin () {
      this.loggedIn = true
    },

    handleLogout () {
      this.loggedIn = false
    },

    isLoggedIn: function () {
      // TODO: extract to a service
      const authToken = localStorage.getItem('authToken')
      if (authToken === null) { return false }

      const token = jwt_decode(authToken)
      if (Date.now() >= token.exp * 1000) { return false }

      return true
    }
  }
}
</script>
