const upsertMixin = {
  methods: {
    formTitle: (id, modelName) => {
      if (id !== undefined) { return `Editar ${modelName}` }

      return `Cadastrar ${modelName}`
    },

    handleFormError (error) {
      const errorMessage = error.response.data.errors[0]
      this.$toast.error(errorMessage)
    }
  }
}

export default upsertMixin
