<template>
  <main>
    <order-modal
      :order="selectedOrder"
      @closeOrderModal="handleCloseModal"
      :render="renderOrderModal"
      @updateOrderStatus="handleUpdateOrderStatus"
    />

    <div class="d-flex flex-column flex-md-row" v-if="authenticated">
      <div class="col-12 col-md-3 me-md-3 mb-5">
        <h3 class="text-center">
          Compra de material
        </h3>

        <order-card
          v-for="order in materialPurchaseOrders"
          :key="order.id"
          :order="order"
          @openOrderModal="openModal(order)"
        />
      </div>

      <hr class="d-md-none">

      <div class="col-12 col-md-3 me-md-3 mb-5">
        <h3 class="text-center">
          Progresso
        </h3>

        <order-card
          v-for="order in progressOrders"
          :key="order.id"
          :order="order"
          @openOrderModal="openModal(order)"
        />
      </div>

      <hr class="d-md-none">

      <div class="col-12 col-md-3 me-md-3 mb-5">
        <h3 class="text-center">
          Pronto para entrega
        </h3>

        <order-card
          v-for="order in readyForDeliveryOrders"
          :key="order.id"
          :order="order"
          @openOrderModal="openModal(order)"
        />
      </div>

      <hr class="d-md-none">

      <div class="col-12 col-md-3">
        <h3 class="text-center">
          Entregue
        </h3>

        <order-card
          v-for="order in deliveredOrders"
          :key="order.id"
          :order="order"
          @openOrderModal="openModal(order)"
        />
      </div>
    </div>

  </main>
</template>

<script>
import OrderCard from '@/components/order/OrderCard'
import createOrder from '@/models/order'
import OrderModal from '@/components/order/OrderModal'
/* eslint-disable camelcase */
import jwt_decode from 'jwt-decode'
/* eslint-enable camelcase */

export default {
  components: {
    OrderCard,
    OrderModal
  },

  data () {
    return {
      ordersRelation: {},
      materialPurchaseOrders: [],
      progressOrders: [],
      readyForDeliveryOrders: [],
      deliveredOrders: [],
      renderOrderModal: false,
      selectedOrder: {}
    }
  },

  mounted () {
    this.fetchOrders()
  },

  computed: {
    authenticated () {
      const authToken = localStorage.getItem('authToken')
      if (authToken === undefined) { return false }
      const token = jwt_decode(authToken)
      if (Date.now() >= token.exp * 1000) { return false }

      return true
    }
  },

  methods: {
    fetchOrders () {
      this.$store.dispatch('orderGrouped')
        .then(response => {
          this.materialPurchaseOrders = response.data.elements.material_purchase?.map(order => {
            return createOrder(order)
          })
          this.progressOrders = response.data.elements.progress?.map(order => {
            return createOrder(order)
          })
          this.readyForDeliveryOrders = response.data.elements.ready_for_delivery?.map(order => {
            return createOrder(order)
          })
          this.deliveredOrders = response.data.elements.delivered?.map(order => {
            return createOrder(order)
          })
        })
        .catch(error => console.log(error))
    },

    handleCloseModal () {
      this.renderOrderModal = false
    },

    handleUpdateOrderStatus () {
      this.fetchOrders()
    },

    openModal (order) {
      this.selectedOrder = order
      this.renderOrderModal = true
    }
  }
}
</script>
