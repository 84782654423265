<template>
  <div class="card card-body mb-1">
    <span>Produto: {{ orderItem.product.name }}</span>
    <span>Preço: {{ orderItem.formattedPrice }}</span>
    <span>Descrição: {{ orderItem.description }}</span>
  </div>
</template>

<script>
export default {
  props: {
    orderItem: {
      required: true,
      type: Object
    }
  }
}
</script>
