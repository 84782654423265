<template>
  <form-session>
    <label for="name">{{label}}</label>
    <span
      class="text-secondary ms-2"
      v-if="optional == false"
      data-test="required-message"
    >
      (obrigatório)
    </span>
    <input
      type="date"
      class="form-control"
      @input="(event) => this.$emit('input', event.target.value)"
      :value="value"
      :class="hasError(errorMessage)"
      autofocus="autofocus"
    />
    <span class="text-danger">{{errorMessage}}</span>
  </form-session>
</template>

<script>
import FormSession from './FormSession.vue'
import formComponents from '../mixins/form-components'

export default {
  components: {
    FormSession
  },

  mixins: [
    formComponents
  ]
}
</script>
