<template>
  <div class="mb-3">
     <h3 class="text-center">Items</h3>
    <order-item-card
      v-for="orderItem in orderItems"
      :key="orderItem.id"
      :orderItem="orderItem" />
  </div>
</template>

<script>
import OrderItemCard from './OrderItemCard.vue'
export default {
  components: { OrderItemCard },

  props: {
    orderItems: {
      type: Array,
      required: true
    }
  }
}
</script>
