<template>
  <main>
    <h1>Login</h1>
    <form @submit.prevent="handleSubmit">
      <form-session>
        <label for="email">Email</label>
        <input type="email" v-model="user.email" class="form-control" autofocus required/>
      </form-session>

      <form-session>
        <label for="password">Senha</label>
        <input type="password" v-model="user.password" class="form-control" required autocomplete/>
      </form-session>

      <form-button>
        Entrar
      </form-button>
    </form>
  </main>
</template>

<script>
import FormButton from '../../components/form/FormButton.vue'
import FormSession from '../../components/form/FormSession.vue'
import User from '../../models/user'
import SessionManager from '../../services/session_manager'

export default {
  components: {
    FormSession,
    FormButton
  },

  data () {
    return {
      user: new User()
    }
  },

  methods: {
    handleSubmit () {
      this.$store.dispatch('authenticateUser', this.userPayload())
        .then(response => this.handleSuccess(response))
        .catch(error => this.handleError(error))
    },

    userPayload () {
      return {
        email: this.user.email,
        password: this.user.password
      }
    },

    handleSuccess (response) {
      SessionManager.newSession(response.data.token)
      this.$router.push({ name: 'home' })
      this.$emit('login-success')
    },

    handleError (error) {
      if (error.response.status === 401) {
        this.$toast.error('Email e/ou senha incorreto(s).')
      } else {
        this.$toast.error('Houve um erro inesperado, tente novamente mais tarde.')
      }
    }
  }

}
</script>

<style>

</style>
