<template>
  <div>
    <h1 data-test="title">{{ formTitle(this.clientId, 'Cliente') }}</h1>
    <div>
      <form class="form" @submit.prevent="handleSubmit">
        <input-field
          name="name"
          label="Nome"
          :errorMessage="handleError(this.$v)"
          v-model="name"
          autofocus
          data-test="name"
        />

        <input-field
          name="phone"
          label="Telefone"
          :errorMessage="handleError(this.$v)"
          v-model="phone"
          data-test="phone"
        />

        <form-button>
          Salvar
        </form-button>
      </form>
    </div>
  </div>
</template>

<script>
import FormButton from '../../components/form/FormButton.vue'
import { required, numeric } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import InputField from '../../components/form/InputField.vue'
import upsertMixin from '../mixins/upsert'
import formErrorHandler from '../mixins/form-error-handler'

export default {
  components: {
    'form-button': FormButton,
    'input-field': InputField
  },

  mixins: [
    upsertMixin,
    formErrorHandler
  ],

  data () {
    return {
      clientId: undefined,
      name: undefined,
      phone: undefined
    }
  },

  mounted () {
    this.clientId = this.$route.params.id

    if (this.clientId != null) {
      this.$store.dispatch('getClient', this.clientId)
        .then(response => {
          this.name = response.data.name
          this.phone = response.data.phone
        })
        .catch(error => {
          if (error.response.code === 404) {
            this.$toast.error('Cliente não existe.')
          } else {
            this.$toast.error('Houve um erro inesperado, tente novamente mais tarde.')
          }
        })
    } else {
      this.name = ''
      this.phone = ''
    }
  },

  setup: () => ({ $v: useVuelidate() }),

  validations () {
    return {
      name: { required },
      phone: { required, numeric }
    }
  },

  computed: {
    saveMethod () {
      if (this.clientId === undefined) { return 'createClient' }

      return 'updateClient'
    }
  },

  methods: {
    async handleSubmit () {
      const isFormValid = await this.$v.$validate()
      if (isFormValid === false) return

      this.$store.dispatch(this.saveMethod, this.clientPayload())
        .then(response => this.handleSuccess(response))
        .catch(error => this.handleFormError(error))
    },

    handleChange (params) {
      const field = params.field
      this[field] = params.event.target.value
    },

    handleSuccess (response) {
      this.$toast.success('Cliente cadastrado com sucesso.')
      const clientId = response.data.id
      this.$router.push({ name: 'show-client', params: { id: clientId } })
    },

    clientPayload () {
      return { id: this.clientId, name: this.name, phone: this.phone }
    }
  }
}
</script>
