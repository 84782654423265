<template>
  <form-session>
    <label data-test="field-label">{{label}}</label>
    <input
      class="form-control"
      :value="value"
      disabled="true"
      :data-test="dataTest"
    />
  </form-session>
</template>

<script>
import FormSession from './FormSession.vue'

export default {
  components: {
    FormSession
  },

  props: {
    label: {
      required: true,
      type: String
    },
    value: {
      required: true,
      type: String
    },
    dataTest: {
      required: false,
      type: String
    }
  }
}
</script>

<style>

</style>
