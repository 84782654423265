const showMixin = {
  methods: {
    handleError (error) {
      if (error.response.code === 404) {
        this.$toast.error('Registro não existe.')
      } else {
        this.$toast.error('Houve um erro inesperado, tente novamente mais tarde.')
      }
    }
  }
}

export default showMixin
