<template>
  <div class="card">
    <div class="card-body">
      <h2>Produto</h2>
      <show-field label="Nome" :value="name" dataTest="name-field" />
      <show-field label="Preço" :value="priceValue" dataTest="price-field" />
      <show-field label="Status" :value="statusName" dataTest="status-field" />
    </div>
  </div>
</template>

<script>
import showMixin from '../mixins/show-mixin'
import ShowField from '../../components/form/ShowField.vue'

export default {
  components: {
    ShowField
  },

  mixins: [showMixin],

  data () {
    return {
      productId: this.$route.params.id,
      name: '',
      price: '',
      status: ''
    }
  },

  mounted () {
    this.$store.dispatch('getProduct', this.productId)
      .then(response => {
        const product = response.data
        this.name = product.name
        this.price = product.price
        this.status = product.status
      })
      .catch(error => this.handleError(error))
  },

  computed: {
    priceValue () {
      if (this.price === '') { return '' }

      const floatValue = parseFloat(this.price).toFixed(2)
      return `R$ ${floatValue.toString().replace('.', ',')}`
    },

    statusName () {
      if (this.status === undefined) { return '' }
      if (this.status === 'active') { return 'Ativo' }

      return 'Inativo'
    }
  }
}
</script>
