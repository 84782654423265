<template>
  <button class="btn btn-primary" type="submit">
    <slot></slot>
  </button>
</template>

<script>
export default {

}
</script>
