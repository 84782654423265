<template>
  <form-session>
    <label>{{ label }}</label>
    <span
      class="text-secondary ms-2"
      v-if="optional == false"
      data-test="required-message"
    >
      (obrigatório)
    </span>

    <select
      class="form-select"
      @input="(event) => this.$emit('input', event.target.value)"
      :value="value"
      :class="hasError(errorMessage)"
      autofocus="autofocus"
    >
      <option value=""></option>
      <option
        v-for="option in options"
        :value="option.value"
        :key="option.value"
      >
        {{ option.label }}
      </option>
    </select>
    <span class="text-danger">{{errorMessage}}</span>
  </form-session>
</template>

<script>
import formComponents from '../mixins/form-components'
import FormSession from './FormSession.vue'

export default {
  components: {
    FormSession
  },

  mixins: [
    formComponents
  ],

  props: {
    options: {
      type: Array,
      required: true
    }
  }
}
</script>
