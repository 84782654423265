<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <router-link :to='{ name: "home" }' class="navbar-brand">
        Imagine Colorido
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" @click.stop="toggleNavbar()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent" v-bind:class="{ 'show': show }">
        <ul
          class="navbar-nav me-auto mb-2 mb-lg-0"
          v-if="loggedIn"
          data-test="dropdown-options"
        >
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Cliente
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link :to="{ name: 'new-client' }" class="dropdown-item">
                  Novo
                </router-link>
              </li>
              <li><hr class="dropdown-divider"></li>
              <li>
                <router-link :to="{ name: 'list-client' }" class="dropdown-item">
                  Buscar
                </router-link>
              </li>
            </ul>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Produto
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link :to="{ name: 'new-product' }" class="dropdown-item">
                  Novo
                </router-link>
              </li>
              <li><hr class="dropdown-divider"></li>
              <li>
                <router-link :to="{ name: 'list-product' }" class="dropdown-item">
                  Buscar
                </router-link>
              </li>
            </ul>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Pedido
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link :to="{ name: 'new-order' }" class="dropdown-item">
                  Novo
                </router-link>
              </li>
              <li><hr class="dropdown-divider"></li>
              <li>
                <router-link :to="{ name: 'list-product' }" class="dropdown-item">
                  Buscar
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
        <div class="ms-auto">
          <button
            class="btn btn-outline-success"
            @click="logout"
            v-if="loggedIn"
            data-test="logout-button"
          >
            Sair
          </button>
          <router-link
            :to="{ name: 'new-session' }"
            class="btn btn-outline-success"
            v-else
            data-test='login-button'
          >
            Entrar
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import SessionManager from '../services/session_manager'

export default {
  props: {
    loggedIn: {
      type: Boolean,
      require: true
    }
  },

  data () {
    return {
      show: false
    }
  },

  methods: {
    logout () {
      SessionManager.destroySession()
      this.$router.push({ name: 'new-session' })
      this.$emit('logout')
    },

    toggleNavbar () {
      this.show = !this.show
    }
  },

  watch: {
    $route (to, from) {
      this.show = false
    }
  }
}
</script>

<style>

</style>
