<template>
  <div class="card">
    <div class="card-header">
      <h2>Pedido</h2>
    </div>
    <div class="card-body">
      <show-field label="Cliente" :value="clientName" />
      <show-field label="Data de entrega" :value="deliveryForecast" />
    </div>

    <h3 class="text-center">Items</h3>
    <order-item-card
      v-for="orderItem in order.orderItems"
      :key="orderItem.id"
      :orderItem="orderItem" />
  </div>
</template>

<script>
import ShowField from '@/components/form/ShowField.vue'
import showMixin from '@/views/mixins/show-mixin'
import createOrder from '@/models/order'
import OrderItemCard from '../../components/order_item/OrderItemCard.vue'

export default {
  components: {
    ShowField,
    OrderItemCard
  },

  mixins: [showMixin],

  data () {
    return {
      order: {},
      clientName: '',
      deliveryForecast: ''
    }
  },

  mounted () {
    const orderId = this.$route.params.id
    this.$store.dispatch('getOrder', orderId)
      .then(response => {
        this.order = createOrder(response.data)
        this.clientName = this.order.clientName
        this.deliveryForecast = this.order.deliveryForecast
      })
      .catch(error => this.handleError(error))
  }

}
</script>
