import Vue from 'vue'
import App from './App.vue'
import api from './lib/api'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import store from './store/index'

Vue.config.productionTip = false

Vue.prototype.$http = api

Vue.use(Toast, { hideProgressBar: true })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
