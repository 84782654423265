<template>
  <div
    class="card mt-3 text-white"
    :class="cardStyle"
    data-test="order-card"
    @click="handleClick"
  >
    <div class="card-header">
      <label data-test="order-card-title">
        {{ order.clientName }}
      </label>
    </div>
    <div class="card-body">
      <label>Entrega: {{ order.deliveryForecast }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      require: true,
      type: Object
    }
  },

  computed: {
    cardStyle () {
      if (this.order.status === 'material_purchase') { return 'bg-secondary' }
      if (this.order.status === 'progress') { return 'bg-info' }
      if (this.order.status === 'ready_for_delivery') { return 'bg-primary' }
      if (this.order.status === 'delivered') { return 'bg-success' }

      return undefined
    }
  },

  methods: {
    handleClick () {
      this.$emit('openOrderModal', this.order)
    }
  }
}
</script>
