<template>
  <main>
    <h1 data-test="title">{{ formTitle(this.productId, 'Produto') }}</h1>

    <form @submit.prevent="handleSubmit">
      <input-field
        label="Nome"
        v-model="name"
        :errorMessage="handleError($v.name)"
        data-test="name"
      />

      <input-field
        label="Preço"
        v-model="price"
        :errorMessage="handleError($v.price)"
        data-test="price"
      />

      <select-field
        label="Status"
        v-model="status"
        :options="options"
        :errorMessage="handleError($v.status)"
        data-test="status"
      />
      <form-button>Salvar</form-button>
    </form>
  </main>
</template>

<script>
import upsertMixin from '../mixins/upsert'
import useVuelidate from '@vuelidate/core'
import { required, numeric } from '@vuelidate/validators'
import formErrorHandler from '../mixins/form-error-handler'
import InputField from '../../components/form/InputField.vue'
import FormButton from '../../components/form/FormButton.vue'
import SelectField from '../../components/form/SelectField.vue'

export default {
  components: {
    InputField,
    FormButton,
    SelectField
  },

  data () {
    return {
      productId: undefined,
      name: undefined,
      price: undefined,
      status: undefined
    }
  },

  mixins: [
    upsertMixin,
    formErrorHandler
  ],

  setup: () => ({ $v: useVuelidate() }),

  validations () {
    return {
      name: { required },
      price: { required, numeric },
      status: { required }
    }
  },

  mounted () {
    this.productId = undefined
    this.productId = this.$route.params.id
    if (this.productId !== undefined) {
      this.$store.dispatch('getProduct', this.productId)
        .then(response => {
          const product = response.data
          this.name = product.name
          this.price = product.price
          this.status = product.status
        })
    }
  },

  computed: {
    options: () => {
      return [
        { label: 'Ativo', value: 'active' },
        { label: 'Inativo', value: 'inactive' }
      ]
    },

    saveMethod () {
      if (this.productId === undefined) { return 'createProduct' }

      return 'updateProduct'
    }
  },

  methods: {
    async handleSubmit () {
      if (await this.$v.$validate() === false) return
      this.$store.dispatch(this.saveMethod, this.productPayload())
        .then(response => this.handleSuccess(response))
        .catch(error => this.formErrorHandler(error))
    },

    handleSuccess (response) {
      this.$toast.success('Produto cadastrado com sucesso.')
      const clientId = response.data.id
      this.$router.push({ name: 'show-product', params: { id: clientId } })
    },

    productPayload () {
      return {
        id: this.productId,
        name: this.name,
        price: this.price,
        status: this.status
      }
    }
  }
}
</script>
