<template>
  <div class="card">
    <div class="card-body">
      <h2>Cliente</h2>
      <show-field label="Nome" :value="name" dataTest="name-field" />
      <show-field label="Telefone" :value="phone" dataTest="phone-field" />

      <hr>

      <h3>Pedidos</h3>

      <div class="d-flex justify-content-between">
        <div class="card col-3 me-3" v-for="order in orders" :key="order.id">
          <div class="card-body">
            <h5>Status: {{ order.status }}</h5>
            <h5>Criado em: {{ order.created_at }}</h5>
            <router-link :to='{ name: "show-order" }' class="btn btn-outline-info btn-sm float-end">
              Mais informações
            </router-link>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import ShowField from '../../components/form/ShowField.vue'
import showMixin from '../mixins/show-mixin'

export default {
  components: {
    ShowField
  },

  mixins: [showMixin],

  data () {
    return {
      clientId: this.$route.params.id,
      name: '',
      phone: '',
      client: {},
      orders: []
    }
  },

  mounted () {
    this.$store.dispatch('getClient', this.clientId)
      .then(response => {
        this.name = response.data.name
        this.phone = response.data.phone
        this.orders = response.data.orders
      })
      .catch(error => {
        this.handleError(error)
      })
  }
}
</script>

<style>

</style>
