<template>
  <transition name="fade">
    <div class="modal-component">
      <div
        class="modal-component__background"
      >
        <div class="modal-component__card card col-10 col-md-6 offset-1 offset-md-3 mt-5">
          <div class="card-header d-flex justify-content-between">
            <h3 data-test="modal-title">{{ title }}</h3>
            <button
              class="btn btn-sm btn-outline-secondary"
              @click.prevent="closeModal"
            >
              X
            </button>
          </div>
          <div class="card-body">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: true
    }
  },

  methods: {
    closeModal () {
      this.$emit('closeModal')
    }
  }

}
</script>

<style lang="scss" scoped>

  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .modal-component {
    &__background {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      z-index: 5;
      background-color: rgba(0, 0, 0, 0.75);
    }

    &__card {
      z-index: 6;
    }
  }
</style>
